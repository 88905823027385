import _get from 'lodash/get';

export const verificationMessage = `
  <div class="content">
    <p>
      Vous n'avez pas encore activé votre compte !
      <br>
      Activez-le en cliquant sur le lien envoyé par email au moment de votre inscription.
    </p>
    <p>
      <strong>Vous ne l'avez pas reçu ?</strong>
      <br>
      Vérifiez vos spams ou
      cliquez ci-dessous pour recevoir à nouveau le lien d'activation.
    </p>
  </div>
`;

export const sentVerificationMessage = `
  <div class="content">
    <p>
      Si vous ne trouvez pas l'email dans votre boîte de réception principale,
      vérifiez dans l'onglet <strong>Spams</strong> ou <strong>Promotions</strong>.
    </p>
    <p>
      Ce nouveau lien invalide tous les précédents.
    </p>
  </div>
`;

export default {
  methods: {
    handleAuthError(error, api, email, redirect = () => {}) {
      if (_get(error, 'response.data.code') === 'EMAIL_NOT_VERIFIED') {
        this.$buefy.dialog.confirm({
          title: 'Activez votre compte',
          message: verificationMessage,
          size: 'is-small',
          confirmText: 'Renvoyer à nouveau',
          cancelText: 'Retour',
          focusOn: 'cancel',
          closeOnConfirm: false,
          onConfirm: (_, modal) => {
            const loader = this.$buefy.loading.open();
            api.sendVerificationEmail(email)
              .then(() => {
                this.$buefy.dialog.alert({
                  title: 'Un nouveau lien vous a été envoyé',
                  message: sentVerificationMessage,
                  size: 'is-small',
                });
                modal.close();
              })
              .finally(() => loader.close());
          },
        });
        redirect();
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};
