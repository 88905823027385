<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      email: '',
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/askNewPassword', this.email)
        .then(() => this.$buefy.dialog.alert('Votre demande a été prise en compte, vérifiez votre boîte mail. Pensez à vérifier vos spams si vous ne trouvez pas l\'email.'))
        .catch(() => this.$buefy.dialog.alert({
          type: 'is-danger',
          message: 'Nous n\'avons pas trouvé de compte Teachizy correspondant.',
        }))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="form p-5">
    <form novalidate @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-4">
        Mot de passe oublié
      </h1>

      <b-field
        label="Adresse email"
        v-bind="$getErrorProps($v.email, ['required', 'email'])"
      >
        <b-input v-model="email" type="email" />
      </b-field>

      <b-field>
        <b-button type="is-primary" native-type="submit" :loading="isLoading" expanded>
          Envoyer
        </b-button>
      </b-field>

      <b-field class="has-text-centered">
        <b-button type="is-text" @click="$emit('switch')">
          Me connecter
        </b-button>
      </b-field>
    </form>

    <hr>

    <p>
      Pas encore de compte ?
      <router-link class="tdecoration-underline" to="/inscription">
        Inscrivez-vous gratuitement
      </router-link>
    </p>
  </section>
</template>
